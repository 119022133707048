/*Source: https://github.com/jstejada/react-typist/blob/69445a87314b52454f050045961ac32090739726/dist/Typist.css*/

.Typist .Cursor {
  display: inline-block;
}

.Typist .Cursor--blinking {
  opacity: 1;
  animation: blink 1.5s linear infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
